import React from "react"
import Pagelayout from "../layouts/page"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import { Col, Row, Button} from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"


// styles
const Pageheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 10px 0 50px 0;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colours.common.white};
  background-color: ${({ theme }) => theme.colours.primary.main};
  border-color: #f78d1d;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  padding: 5px 20px;

  &:hover, :active, .active, .focus, :focus, :not(:disabled):not(.disabled).active, :not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle  {
    color: ${({ theme }) => theme.colours.common.white};
    background-color: ${({ theme }) => theme.colours.common.black};
    border-color: ${({ theme }) => theme.colours.common.black};
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    padding: 5px 20px;
  }

  &:focus, :focus {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colours.primary.light};
  }
`

const GetQuote = styled.div`
  text-align: center;
  padding-bottom: 20px;
  display: block;
`

const Contentcontainer = styled(Col)`
  background-color: ${({ theme }) => theme.colours.common.white};
  margin-top: 0px;
  padding: 35px;
  margin-bottom: 50px;

  @media (min-width: 576px) { 
    padding: 25px;
  }

  @media (min-width: 992px) { 
    margin-top: 25px;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
  }

  & h1 {
    color: ${({ theme }) => theme.colours.primary.main};
    font-weight: 400;
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 2rem;
  }

  & strong {
    display:block;
    margin-top: 30px;
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.font.headings};
    color: #f78d1d;
  }

  & span {
    color: #f78d1d;
  }

  & div {
    
  }
`

const ServicesDetailsPage = ({ data }) => {

//  const faqNode = data.directus.faq;

 return (
  <>
      <Seo title={`${data.directus.concut_services_by_id.title} - ${data.directus.concut_settings.site_title}`} description={data.directus.concut_services_by_id.seo_description} author={data.directus.concut_settings.site_author}  />
      <Pagelayout logo={data.directus.concut_settings.site_logo}>
          <Row>
            <Col>
                  <Pageheading>{data.directus.concut_services_by_id.title}</Pageheading>
            </Col>
          </Row>
          <Row>
          <div className="subtitle"
                  dangerouslySetInnerHTML={{
                    __html: data.directus.concut_services_by_id.subtitle,
                  }}
                />
          <GetQuote>
            <Link to="/contact"><StyledButton className="btn btn-primary">Get A Quote</StyledButton></Link>
          </GetQuote>
          </Row>
          <Row className="serviceContainer">
          <Col sm={4} lg={4} className="serviceImg">
          {
            data.directus.concut_services_by_id.image1 && data.directus.concut_services_by_id.image1.imageFile  ? <GatsbyImage image={data.directus.concut_services_by_id.image1.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.concut_services_by_id.image1.title} objectPosition="center center" /> : <p></p>
          }
          </Col>
          <Col sm={4} lg={4} className="serviceImg d-none d-sm-block">
          {
            data.directus.concut_services_by_id.image2 && data.directus.concut_services_by_id.image2.imageFile  ? <GatsbyImage image={data.directus.concut_services_by_id.image2.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.concut_services_by_id.image2.title} objectPosition="center center" /> : <p></p>
          }
          </Col>
          <Col sm={4} lg={4} className="serviceImg d-none d-sm-block">
          {
            data.directus.concut_services_by_id.image3 && data.directus.concut_services_by_id.image3.imageFile  ? <GatsbyImage image={data.directus.concut_services_by_id.image3.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.concut_services_by_id.image3.title} objectPosition="center center" /> : <p></p>
          }
          </Col>
          </Row>

          <Row>
          <Contentcontainer sm={12} lg={12} className="details">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.directus.concut_services_by_id.content1,
                  }}
                />
              </Contentcontainer>
          </Row>

{/*           
          <Row>
              <Col sm={12} lg={6} className="serviceImg"><GatsbyImage image={data.directus.concut_services_by_id.image1.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.concut_services_by_id.image1.title} objectPosition="center center" /></Col>
              <Contentcontainer sm={12} lg={6} className="details">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.directus.concut_services_by_id.content1,
                  }}
                />
              </Contentcontainer>
          </Row>
          <Row>
            <Col sm={12} lg={6} className="serviceImg d-none d-sm-block d-lg-none"><GatsbyImage image={data.directus.concut_services_by_id.image2.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.concut_services_by_id.image2.title} objectPosition="center center" /></Col>
            <Contentcontainer sm={12} lg={6} className="details">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.directus.concut_services_by_id.content2,
                  }}
                />
              </Contentcontainer>
              <Col sm={12} lg={6} className="serviceImg d-none d-lg-block"><GatsbyImage image={data.directus.concut_services_by_id.image2.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.concut_services_by_id.image2.title} objectPosition="center center" /></Col>
          </Row>
          <Row>
              <Col sm={12} lg={6} className="serviceImg">
              {
                data.directus.concut_services_by_id.image3 && data.directus.concut_services_by_id.image3.imageFile  ? <GatsbyImage image={data.directus.concut_services_by_id.image3.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.concut_services_by_id.image3.title} objectPosition="center center" /> : <p></p>
              }
              </Col>
              
              <Contentcontainer sm={12} lg={6} className="details">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.directus.concut_services_by_id.content3,
                  }}
                />
              </Contentcontainer>
          </Row> */}

      </Pagelayout>

      </>
)
        }

export default ServicesDetailsPage

export const query = graphql`
query ($id: ID!) {
  directus {
    concut_settings {
      site_title
      site_description
      site_author
      site_logo {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 300
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
    concut_services_by_id(id: $id) {
      title
      seo_description
      subtitle
      content1
      image1 {
        imageFile {
          childImageSharp {
            gatsbyImageData(height: 300, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
           
          }
        }
        title
        id
      }
      image2 {
        imageFile {
          childImageSharp {
            gatsbyImageData(height: 300, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        title
        id
      }
      image3 {
        imageFile {
          childImageSharp {
            gatsbyImageData(height: 300, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        title
        id
      }
    }
  }
} 
`

/*gatsbyImageData(width: 420, height: 300, layout: FIXED, placeholder: BLURRED, formats: WEBP)*/